* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}

@media (min-width: 768px) {
  html,
  body,
  #root {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  html,
  body,
  #root {
    font-size: 20px;
  }
}

#root {
  display: flex;
  flex-direction: column;
}

h1,
h2 {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}

h1 {
  font-size: 2rem;
  margin: 1rem 0 0 0;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}
